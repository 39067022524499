import React from "react";
import Container from "../../../components/chat-channel/Container";
import Layout from "../../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { OnScrollPopup } from "../../conversational-ai-platform/hr-chatbot";
export default function DemoPage() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Webinar for HR & IT Help Desk AI Chatbot by Workativ | Register for Workativ Assistant Webinar Here"
        description="Join our webinar on how to build Conversational AI Chatbot. Learn how to automate IT & HR support with AI Chatbot with process automation."
        keywords={["workativ terms and conditions"]}
        ogTitle="Free Webinar for HR & IT Help Desk AI Chatbot by Workativ | Register for Workativ Assistant Webinar Here"
        ogDescription="Join our webinar on how to build Conversational AI Chatbot. Learn how to automate IT & HR support with AI Chatbot with process automation."
      />
      <Container>
        <Layout logoFor="ASSISTANT">
          <DemoPageContentAndForm formName={"DemoForm"}>
            <DemoPageContentAndForm.Header>
              Provide an excellent onboarding experience for employees through
              workflow automations and AI chatbot from Workativ
            </DemoPageContentAndForm.Header>
            <DemoPageContentAndForm.Content>
              Many businesses are being bogged down with manual work that can be
              handled through automation. Automation can help reduce the time
              and efforts on low-level tasks and repetitive tasks for HR Teams,
              IT Teams and Helpdesk Teams. By not automating, these teams
              ultimately waste a significant amount of time and therefore
              money.  
            </DemoPageContentAndForm.Content>
            <DemoPageContentAndForm.H6>
              Join us to learn more about:
            </DemoPageContentAndForm.H6>
            <DemoPageContentAndForm.Ul>
              {liContent.map(li => (
                <DemoPageContentAndForm.Li>
                  {li.content}
                </DemoPageContentAndForm.Li>
              ))}
            </DemoPageContentAndForm.Ul>
          </DemoPageContentAndForm>
          {/* <OnScrollPopup /> */}
        </Layout>
      </Container>
    </>
  );
}

const liContent = [
  {
    content: "Benefits of workplace support automation "
  },
  {
    content:
      "Workativ’s one click integration with HRMS and IT tools to ease the onboarding process through automation "
  },
  {
    content:
      "Use cases and examples that cover the employee experience during onboarding "
  },
  {
    content:
      "The value you can provide to your teams and employees with Workativ "
  }
];

DemoPageContentAndForm.Header = ({ children }) => {
  return (
    <>
      <h1 className="webinar_header-bottom">{children}</h1>
    </>
  );
};
DemoPageContentAndForm.Content = ({ children }) => {
  return <p>{children}</p>;
};
DemoPageContentAndForm.H6 = ({ children }) => {
  return <h6>{children}</h6>;
};
DemoPageContentAndForm.Ul = ({ children }) => {
  return <ul>{children}</ul>;
};
DemoPageContentAndForm.Li = ({ children }) => {
  return <li>{children}</li>;
};

DemoPageContentAndForm.Form = ({ children }) => {
  return <>{children}</>;
};

export function DemoPageContentAndForm({ children, formName }) {
  return (
    <section className="skit_contactus sign_up-form_us demo_page webinar_forms_head">
      <div className="container signup_form">
        <div className="row beta_change_form">
          <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 ">
            <h1 class="webinar_head">Webinar</h1>
            {children}
            <p className="mb_0_webianr">
              <b>Can't make it?</b> Register anyway and we'll send you the
              recording after the webinar.
            </p>
            <div className="webinar_footer_img">
              <h6>Presented By</h6>
              <div className="webinar_form_head_photo">
                <img
                  src={require("../../../assets/images/ic.png")}
                  alt="webinar-ic"
                />
              </div>
              <div className="webinar_form_head_photo_info">
                <div className="webinar_name">Indupriya C</div>
                <div className="webinar_title">Marketing Lead</div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 demo_page_right">
            {formName === "DemoForm" ? <DemoForm /> : null}
          </div>
        </div>
      </div>
    </section>
  );
}

function DemoForm() {
  return (
    <div class="webinar_form ">
      <div class="iframe_zoho">
        <iframe
          src="https://us02web.zoom.us/webinar/register/WN_Y7uOw-F1ReS07do3tDHbRQ"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
}
